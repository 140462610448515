/* Main styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.header-title {
  border-bottom: 0.125em solid #F5F5F5;
}

.navbar-link,
.footerProfile,
.postTitle,
.cardLink,
.signupFooter,
.loginFooter,
.loginLink,
.signupLink {
  text-decoration: none;
  color: #0A0909;
}

.profileName {
  margin: 1rem;
}


footer {
  background-color: #F5F5F5;
  clear: both;
  z-index: 1;
  position: static;
}

.footerProfile,
.navbar-link,
.userLink {
  color: #0A0909;
}

/* Home STYLING */

.heroSection,
.getstartedSection,
.exploreSection {
  background-color: #FFBF86;
}

.heroSection {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  height: 630px;
  flex-wrap: wrap;
}


.carousel-items-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 150px;
  max-width: 100%;
}



@media (max-width: 984px) {
  .heroSection {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 1000px;
  }

  .titleContainer {
    margin-top: 70px;
  }

  .carousel-items-container {
    margin-right: 0px;
    margin-top: -400px;
  }


}


@media (max-width: 768px) {
  .heroSection {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .titleContainer {
    margin-top: 70px;
  }

}



.infoSection {
  border-radius: 100px 100px 0px 0px;
  background-color: #FFBF86;
  margin-top: 100px;
  padding: 50px;
}

.homePhoto {
  width: 280px;
  height: 300px;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.loginBtnNav,
.signupBtnNav {
  text-decoration: none;
  background-color: #FFBF86;
  font-weight: bold;
  color: #0A0909;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.loginBtn,
.signupBtn {
  width: 100%;
  background-color: #FFBF86;
  font-weight: bold;
  color: #0A0909;
  font-size: medium;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.loginBtn:hover,
.signupBtn:hover,
.loginBtnNav:hover,
.signupBtnnAV:hover {
  background-color: #a18100;
  color: #fff2f2;
}


.logoutBtn {
  background-color: #FFBF86;
  font-weight: bold;
  color: #0A0909;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.followBtn {
  background-color: #FFBF86;
  font-weight: bold;
  color: #0A0909;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}


.logoutBtn:hover,
.categoryBtn:hover {
  background-color: #a18100;
  color: #fff2f2;
}


/* Error Styling*/

.errorMsg {
  background-color: #f8d7da;
  font-weight: bolder;
  font-size: 1rem;
  padding: 8px;
  border-radius: 0.32px;
  color: #0A0909;
}


/* Feed Page styling */

.postsWrapper,
.emptyPostsWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: stretch;
}

.feedpostsWrapper,
.profilepostsWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: stretch;
  margin-top: 50px;
  margin-bottom: 50px;
}

.postContainer {
  padding: 32px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  border-radius: 0.8px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
}

.postFooter {
  background-color: #F5F5F5;
  margin-top: auto;
}

.postTitle {
  font-size: 1.5rem;
}

.postCategory {
  font-size: 1rem;
}

.feedContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.searchPhoto,
.feedPhoto {
  height: 800px;
  width: 800px;
}

/* Post Details Page */



.postPhoto {
  border-radius: 16px;
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.postInfo {
  background-color: #F5F5F5;
  border-radius: 10px;
  padding: 15px;
  margin-top: 40px;
  margin-bottom: 40px;
  border: 16px solid #FFBF86;
}

.postDetails {
  border-radius: 16px;
  padding: 10px;
}

.contentContainer {
  margin-top: 50px;
}

.postContent {
  margin-bottom: 1rem;
}

.editPostBtn,
.deletePostBtn,
.deleteModalBtn {
  width: 100px;
  height: 30px;
  background-color: #FFBF86;
  font-weight: bold;
  color: #0A0909;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin: 20px;
}

.editPostLink,
.editCommLink {
  text-decoration: none;
  color: #0A0909;
}

.commSection {
  margin-top: 60px;
  margin-bottom: 100px;
}


.commFormContainer {
  padding: 32px;
  display: flex;
  flex-flow: column wrap;
  margin: auto;
  border-radius: 0.8px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  width: 70%;
  border: 10px solid #FFBF86;
  border-radius: 10px;
}


.commentForm {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  gap: 12px;
}

.comment {
  background-color: #F5F5F5;
  border-radius: 10px;
  padding: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  border: 5px solid #FFBF86;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  width: 70%;
}

.commentBox {
  resize: none;
}

.submitCommBtn {
  width: 65px;
  height: 30px;
  background-color: #FFBF86;
  font-weight: bold;
  color: #0A0909;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}


.commBtn {
  width: 200px;
  height: 30px;
  background-color: #FFBF86;
  font-weight: bold;
  color: #0A0909;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.commBtnsContainer {
  margin-top: 20px;
  margin-bottom: 0;
}

.editCommBtn,
.deleteCommBtn {
  width: 60px;
  height: 30px;
  background-color: #FFBF86;
  font-weight: bold;
  color: #0A0909;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin: 20px;
}

.editPostBtn:hover,
.deletePostBtn:hover,
.deleteModalBtn:hover,
.submitCommBtn:hover,
.commBtn:hover,
.editCommBtn:hover,
.deleteCommBtn:hover,
.followBtn:hover {
  background-color: #a18100;
  color: #fff2f2;
}

/* Create and Edit Form Styles */

.newpostWrapper,
.editpostWrapper {
  background-color: #FFBF86;
}

.editpostWrapper {
  background-color: #FFBF86;
  min-height: 100vh;
}

.newpostContainer,
.editpostContainer {
  padding: 3rem;
  box-shadow: 5px;
  border-radius: 10px;
  background-color: #F5F5F5;
  margin: 3rem;
}


.editCommContainer {
  box-shadow: 5px;
  border-radius: 10px;
  background-color: #F5F5F5;
  margin: 3rem;
}

.currentPhoto {
  width: 150px;
  height: 100px;
}

.removePhoto {
  background-color: #FFBF86;
  font-weight: bold;
  color: #0A0909;
  padding: 5px 5px;
  margin: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.removePhoto:hover {
  background-color: #a18100;
  color: #fff2f2;
}

.createTitle,
.editPostTitle {
  text-align: center;
  margin-bottom: 2rem;
}

.editCommTitle {
  text-align: center;
  margin: 20px;
}

.photoInput {
  margin: 30px;
}

.editCommForm {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.submitFormBtn {
  width: 100%;
  background-color: #FFBF86;
  font-weight: bold;
  color: #0A0909;
  font-size: medium;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.submitFormBtn:hover {
  background-color: #a18100;
  color: #fff2f2;
}

.submitFormBtn:disabled,
.submitCommBtn:disabled {
  background-color: #a18100;
  color: #fff2f2;
  cursor: not-allowed;
}

/* Explore and Category Styling*/

.latestTitle {
  margin-bottom: 5rem;
}

.searchWrapper,
.profileWrapper {
  background-color: #FFBF86;
  width: 100%;
}

.categoryWrapper,
.resultsWrapper,
.feedWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.postCard {
  margin: 10px;
}

.postAuthor {
  padding: 5px;
}

.searchTitle {
  text-align: center;
  margin: 1rem;
}

.searchContainer,
.feedContainer,
.categoryContainer {
  border-radius: 10px;
  background-color: #F5F5F5;
  margin: 3rem;
}

.postHeader,
.postFooter {
  background-color: #ffb473;
}

.resultsContainer {
  padding: 3rem;
}

.profilePhoto {
  margin-right: 10px;
}

.postCategory {
  padding: 5px;
}

.pageLink {
  background-color: #ffb473;
}


.paginationBar {
  margin: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.categoryBtn {
  background-color: #fccc04;
  background-color: #FFBF86;
  font-weight: bold;
  color: #0A0909;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  margin: 10px;
}


/* Login & Signup Page*/

.loginWrapper,
.signupWrapper {
  background-color: #FFBF86;
  min-height: 100vh;
}

.loginContainer,
.signupContainer {
  background-color: #F5F5F5;
}

.loginTitle {
  text-align: center;
  margin: 1rem;
  color: #FFBF86;
}


/* Profile Page Styling */



.profileContainer {
  border-radius: 10px;
  background-color: #F5F5F5;
  margin: 50px;
}

.profileBio {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.followContainer {
  padding: 40px;
}

.emptyPostsWrapper {
  width: 100%;
}

.emptyPostsContainer {
  border-radius: 10px;
  background-color: #FFBF86;
  color: #0A0909;
  margin: 50px;
  padding: 20px;
}

/* Not Found Page */

.notfoundWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: stretch;
}

.notfoundContainer {
  border-radius: 10px;
  background-color: #FFBF86;
  color: #0A0909;
  margin: 50px;
  padding: 20px;
}

main {
  min-height: 100vh;
}